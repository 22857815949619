import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Modal, Row, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import NavbarTop from '../componentes/include/Navbar';
import queryPost from '../Config/Consultas';
import moment from 'moment';
import 'moment/locale/es';
import { alertaError, alertaQuestion, alertaSuccess } from '../helpers/sweatalert2';
import api from '../Api.json';

const URL = `${api.url}${api.posts}`;
const URL_SERVER = api.url;

function Posts() {
  const [posts, setPosts] = useState([])
  const [show, setShow] = useState(false);
  const [verMasPost, setVerMasPost] = useState(30)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getPosts = async () => {
    const data = {
      accion: "getPosts"
    }
    const respuesta = await queryPost(URL, data);
    setPosts(respuesta)
    console.log("respuesta desde el evento", respuesta)
  }

  const handleDeletePost = async (id, index) => {
    alertaQuestion(
      "Se eliminara la publicacion permanentemente",
      "Eliminar",
      async () => {
        const dataDelete = {
          accion: "deletePost",
          id: id
        }
        const respuesta = await queryPost(URL, dataDelete);
        if(!respuesta.error) {
          alertaSuccess(respuesta.message)
          const postUpdate = posts
          postUpdate.data.splice(index, 1);
          setPosts({...posts, postUpdate})
        }
        else alertaError()
      }
    )
  }

  useEffect(() => {
    if(localStorage.getItem('email') === undefined || !localStorage.getItem('email')){
      localStorage.clear();
    }else{
      getPosts()
      moment.locale('es');
      const onScroll = function () {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
          setVerMasPost(val => {return val + 20})
        }
       }
       window.addEventListener('scroll', onScroll)
       return () => window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return posts.data &&  (
    <Container style={{marginTop: 115}}>
      <NavbarTop className="m-0"/>
      <h1 className="text-center fw-bold mb-3"><i>Posts</i></h1>
      <Row>
        <Col xs={12}>
          <Link to={`/NuevoPost`} className='btn btn-primary float-end'>Nuevo Post +</Link>
        </Col>
      </Row>
      <Row className="py-2 px-4">
      <Col xs={12} className='mt-3 overflow-auto  shadow p-0'>
        <Table striped bordered hover size="sm" className="bg-white mb-0 text-center">
          <thead>
            <tr>
              <th>Titulo</th>
              <th>Descripcion</th>
              <th>Video</th>
              <th>Fecha</th>
              <th>Admin</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            { posts.empty && posts.data &&
              posts.data.slice(0,verMasPost).map((val,i) => {
                return(
                  <tr key={i}>
                    <td className="align-middle">{val.titulo}</td>
                    <td className="align-middle">{val.descripcion.substr(0,25) + "..."}</td>
                    <td className="align-middle">{val.video}</td>
                    <td className="align-middle">{moment(val.createdAt).format('DD/MM/YY, h:mm:ss a')}</td>
                    <td className="align-middle">{val.email}</td>
                    <td>
                      {/* <Button variant="outline-primary" onClick={handleShow} className="p-1 rounded-pill border-0"><i className='bx bx-show p-0 align-middle fs-4' ></i></Button> */}
                      <Link to={`/EditarPost/${val.id}`} className="btn btn-outline-info p-1 rounded-pill border-0"><i className='bx bx-edit p-0 align-middle fs-4' ></i></Link>
                      <Button variant="outline-danger" onClick={() => handleDeletePost(val.id, i)} className="p-1 rounded-pill border-0"><i className='bx bx-x p-0 align-middle fs-4'></i></Button>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
            {
              !posts.empty &&
              <div className='bg-white border p-2'>
                <h1 className='text-center text-muted'>{posts.data}</h1>
              </div>
            }
        </Col>
      </Row>
      <Modal centered size="md" show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{border: 0, padding: 6}}></Modal.Header>
        <Modal.Body className="pt-0">
          <div className='shadow bg-white border border-radius overflow-hidden'>
            <div className=" d-flex p-2 text-light bg-blue">
              <img alt='avatar' src="images/all/user.png" className="avatar bg-white"/>
              <div className='d-flex flex-column ms-3'>
                <div className="fw-bold text-start">correoadmin@correo.com</div>
                <div className=" badge text-start p-0 fw-light text-light">fecha</div>
              </div>
          </div>
          <div className="pt-2 px-3 text-start fs-4 fw-bold border-1"> Titulo </div>
          <div className="px-3 text-start">Descripcion del post</div>
          <div className='border mt-4 p-2'>
              <div className='overflow-hidden h-100 text-center'>
                <img alt='avatar' src="images/all/mapa.jpeg" className="img-fluid bg-white"/>
              </div>
            </div>
          </div> 
        </Modal.Body>
      </Modal>
    </Container>
  )
}

export default Posts