import React, { useEffect, useState } from 'react'
import { Button, Col, Container, FloatingLabel, Form, Row, Spinner } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom';
import NavbarTop from '../componentes/include/Navbar';
import queryPost from '../Config/Consultas';
import { alertaSuccess,alertaError } from '../helpers/sweatalert2';
import userIcono from '../imagesGlobal/user.png'
import { Formik } from 'formik';
import api from '../Api.json';

const URI = `${api.url}${api.posts}`;
const URL_SERVER = api.url;

function EditarPost() {
    const {id} = useParams()
    const navigate = useNavigate()
    const [isLoadingFile, setIsLoadingFile] = useState(false)
    const [isLoadingPost, setIsLoadingPost] = useState(false)
    
    const [imagenInput, setImagenInput] = useState([])
    const [files, setFiles] = useState([])
    const [deleteFiles, setDeleteFiles] = useState([])
    const [dataForm, setDataForm] = useState([])
    

    const getPost = async () => {
        const data = {
          accion: "getPost",
          id: id
        }
        const respuesta = await queryPost(URI, data);
        // console.log(respuesta)
        setDataForm(respuesta)
    }

    const getImagesPost = async () => {
        const data = {
          accion: "getImagesPost",
          id: id
        }
        const respuesta = await queryPost(URI, data);
        setFiles(respuesta.data)
    }

    useEffect(() => {
        if(localStorage.getItem('email')){
            getPost()
            getImagesPost()
        }else{
            localStorage.clear();
        }
    }, [id])

   

    const handleFileChange = (e) => {
        setIsLoadingFile(true)
        let files = e.target.files;
        for(let i = 0; i < e.target.files.length; i++){
            setImagenInput([])

            const file = files[i]
            const name = file.name
            const type = name.split('.').pop()
            let preview = URL.createObjectURL(file)

            if(type === "pdf"){
                preview = "../images/icons/pdf.png"
            }

            let fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = (event) => {
                const img = {
                    imgPreview: preview,
                    filePreview: URL.createObjectURL(file),
                    data: event.target.result,
                    type: type,
                    name: name
                }
                const newList = imagenInput
                newList.push(img)
                setImagenInput(newList)
            }
        }
        setIsLoadingFile(false)
    }

    const handleInputChange = e => {
        setDataForm({
            ...dataForm, [e.target.name]: e.target.value
        })
    }

    const handleDeleteImg = index => {
        setImagenInput(files => files.filter((i, itemIndex) => index !== itemIndex))
    }

    const handleDeleteCurrentFile = async (idImage, index) => {
        setFiles(files => files.filter((i, itemIndex) => index !== itemIndex))
        setDeleteFiles(deleteFiles => [...deleteFiles, {id: idImage}])
        alertaSuccess("Se a eliminado la imagen")
    }

    const handleSubmit = async (e) => {
        // e.preventDefault()
        // setIsLoadingPost(true)
        const data = {
            accion: "updatePost",
            dataForm: e,
            deleteFiles: deleteFiles,
            newFiles: imagenInput
        }
        // console.log(data)
        // console.log(data)
        const res = await queryPost(URI, data);
        if(!res.error){
            document.getElementById("formulario-post").reset();
            alertaSuccess(res.message)
            navigate(`/Posts`)
        }else{
            alertaError()
        }
        // setIsLoadingPost(false)
    }

  return dataForm.data && (
    <Container className='mb-4' style={{marginTop: 115}}>
        <NavbarTop className="m-0"/>
        <Row className="flex-column d-flex align-items-center p-0" >
            <Col xs={10} md={8} lg={5} className='border border-1 p-0 shadow border-radius bg-white'>
                {/* <h1 className="text-center mb-3 mt-3">Crear un nuevo Post</h1> */}
                
                <Formik initialValues={dataForm.data} onSubmit={(e) => handleSubmit(e)} onChange={handleInputChange}>
                    {({ handleSubmit, handleChange, values }) => (
                    <Form id="formulario-post" className='p-4' onSubmit={handleSubmit}>
                        <div className=" d-flex text-dark mt-2 mb-3 align-items-center">
                            <img src={`${URL_SERVER}filesUpload/images/avatars/${values.avatar}`} className="avatar"/>
                            <div className="fw-bold text-start ms-3 fs-5">{dataForm.data.email}</div>
                        </div>
                        <FloatingLabel controlId="floatingTitulo" label="Titulo" className="mb-3">
                            <Form.Control value={values.titulo} onChange={handleChange} required name="titulo" placeholder="Escribe un titulo para el post" />
                        </FloatingLabel>
                        <FloatingLabel controlId="floatingPost" label="Post" className="mb-3 bg-white">
                            <Form.Control value={values.descripcion} onChange={handleChange} required as="textarea" name="descripcion" placeholder="Escribe aqui tu post" style={{height: 200}} />
                        </FloatingLabel>
                        <FloatingLabel controlId="floatingVideo" label="Link del video" className="mb-3">
                            <Form.Control value={values.video} onChange={handleChange} name="video" placeholder="Link del video"/>
                        </FloatingLabel>
                        
                        <div className="d-flex align-items-center justify-content-end">
                            <Form.Label htmlFor="inputFileImage" className="btn btn-outline-secondary border-0 p-0"><i className='bx bxs-file-pdf fs-2 p-1'></i></Form.Label>
                            <Form.Label htmlFor="inputFilePdf" className="btn btn-outline-secondary border-0 p-0"><i className='bx bxs-image-add fs-2 p-1'></i></Form.Label>
                        </div>
                        
                        <Form.Control id="inputFileImage" className="d-none" type="file" name="pdf" accept=".pdf" onChange={handleFileChange} />
                        <Form.Control id="inputFilePdf" className="d-none" type="file" name="image" accept=".jpg, .jpeg, .png, .gif" multiple onChange={handleFileChange} />
                        
                        <Row className="border-secondary p-2 rounded" style={{borderStyle: "dashed"}}>
                            {
                                files && 
                                files.map((val,i) => {
                                    return (
                                        <Col key={i}  xs={12} md={4} className='p-2 position-relative bg-primary bg-opacity-50 rounded'>
                                            <div style={{height: 120}}>
                                                {
                                                    val.type === "pdf" ?
                                                    <img src="../images/icons/pdf.png" className="img-fluid h-100 mx-auto d-block" style={{objectFit: "cover"}} /> 
                                                    :
                                                    <img src={URL_SERVER + val.nombre} className="img-fluid h-100 mx-auto d-block" style={{objectFit: "cover"}} />
                                                }
                                                
                                            </div>
                                            <div className="position-absolute end-0 top-0 mt-1 me-2">
                                                <a target="_blank" href={URL_SERVER + val.nombre} className='btn btn-primary p-0 mt-1 me-1'><i className='bx bx-show fs-6 p-1'></i></a>
                                                <Button variant='danger' onClick={() => handleDeleteCurrentFile(val.id, i)} className='p-0 mt-1'><i className='bx bx-trash fs-6 p-1'></i></Button>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                            {isLoadingFile ?
                                <Spinner animation="border" />
                            :
                            imagenInput !== null && 
                                imagenInput.map((val,i) => {
                                    return (
                                        <Col key={i} xs={12} md={4} className='p-2 position-relative bg-secondary bg-opacity-10 rounded'>
                                            <div style={{height: 120}}>
                                                <img src={val.imgPreview} className="img-fluid h-100 mx-auto d-block" style={{objectFit: "cover"}} />
                                            </div>
                                            <p className="mb-0 p-1 text-truncate">{val.name}</p>
                                            <div className="position-absolute end-0 top-0 mt-1 me-2">
                                                <a target="_blank" href={val.filePreview} className='btn btn-primary p-0 mt-1 me-1'><i className='bx bx-show fs-6 p-1'></i></a>
                                                <Button variant='danger' onClick={() => handleDeleteImg(i)} className='p-0 mt-1'><i className='bx bx-trash fs-6 p-1'></i></Button>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                        <Button disabled={isLoadingPost} type="submit" variant='success' className="text-center mx-auto mt-3 d-block">{isLoadingPost ? <Spinner animation="border" /> : "Actualizar Post"}</Button>
                    </Form>
                    )}
                </Formik>
            </Col>
        </Row>
    </Container>
  )
}

export default EditarPost