import { Formik } from 'formik'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Button, Col, Container, FloatingLabel, Form, Modal, Row, Spinner } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import queryPost from '../Config/Consultas'
import NavbarTop from './include/Navbar'
import api from '../Api.json';
import { alertaError, alertaLoading, alertaSuccess, customAlert } from '../helpers/sweatalert2'

const URL_USUARIOS = `${api.url}${api.usuarios}`;
const URL_SERVER = api.url;

function Perfil() {
  const navigate = useNavigate();
  const {id} = useParams()
  const [dataForm, setDataForm] = useState([])
  const [isLoadingPost, setIsLoadingPost] = useState(false)
  const [show, setShow] = useState(false);
  const [currentAvatar, setCurrentAvatar] = useState(localStorage.getItem("avatar")) 
  const avatars = [
    {src:"avatar1.png"},
    {src:"avatar2.png"},
    {src:"avatar3.png"},
    {src:"avatar4.png"},
    {src:"avatar5.png"},
    {src:"avatar6.png"},
    {src:"avatar7.png"},
    {src:"avatar8.png"},
  ]

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setCurrentAvatar(localStorage.getItem("avatar"))
  }

  const handleNewAvatar = (src) => {
    setCurrentAvatar(src)
  }

  const handleUpdateAvatar = async () => {
    if(currentAvatar === localStorage.getItem("avatar")){
      customAlert(
        "Selecciona otro avatar",
        "Este es tu avatar actual",
        "warning"
      )
    }else{
      alertaLoading()
      const data = {
        accion: "updateAvatar",
        id: id,
        avatar: currentAvatar
      }
      const query = await queryPost(URL_USUARIOS, data)
      console.log(query)
      if(query.error){
        alertaError()
      }else{
        alertaSuccess(query.message)
        localStorage.setItem("avatar", currentAvatar)
      }
    }
  }

  const getUser = async () => {
    const data = {
      accion: "getUser",
      id: id
    }
    const query = await queryPost(URL_USUARIOS, data)

    if(query.error){
      alertaError()
    }else{
      setDataForm(query)
    }
  }

  const handleSubmit = async (e) => {
    alertaLoading()
    const data = {
      accion: "updateUser",
      data: e
    }
    const query = await queryPost(URL_USUARIOS, data)
    console.log(query)
    if(query.error){
      alertaError()
    }else{
      localStorage.setItem("nombre", e.nombre)
      localStorage.setItem("apellido", e.apellidos)
      localStorage.setItem("telefono", e.telefono)
      alertaSuccess(query.message)
    }
  }

  const handleSubmitClave = async (e) => {
    if(e.newPassword === e.newPassword2){
      alertaLoading()
      const data = {
        accion: "updateClave",
        data: e,
        token: localStorage.getItem("token")
      }
      const query = await queryPost(URL_USUARIOS, data)
      if(query.error){
        customAlert(
          query.title,
          query.message,
          query.icon
        )
      }else{
        alertaSuccess(query.message)
      }
    }else{
      customAlert(
        "Las contraseñas no coinciden",
        "Para actualizar tu contraseña deben coincidir en ambos campos",
        "warning"
      )
    }
  }

  useEffect(() => {
    if(localStorage.getItem('email')){
      getUser()
    }else{
      navigate("/")
    }
  }, [])


  return dataForm.data && (
    <Container style={{marginTop: 100}}>
      <NavbarTop className="m-0"/>
      <Row className='mb-5'>
      <Col xs={10} md={8} lg={5} className='border border-1 p-2 shadow border-radius bg-white mx-auto'>
          
          <Formik initialValues={dataForm.data} onSubmit={(e) => handleSubmit(e)}>
            {({ handleSubmit, handleChange, values }) => (
            <Form  className='p-3 border-radius border' onSubmit={handleSubmit}>
              <h3 className="text-center">Actualiza tu información</h3>
              <Button className="rounded-circle p-1 mx-auto d-block my-3 position-relative" onClick={handleShow} variant='outline-primary' style={{height:"9rem", width:"9rem"}}>
                <img src={`${URL_SERVER}filesUpload/images/avatars/${localStorage.getItem("avatar")}`} className="bg-white rounded-circle p-2 img-fluid opacity-75"/>
                <i className='bx bx-edit position-absolute top-50 start-50 translate-middle fs-2'></i>
              </Button>
              <FloatingLabel controlId="floatingEmail" label="Correo electronico" className="mb-3">
                <Form.Control type='email' value={values.email} onChange={handleChange} name="email" placeholder="Escribe tu correo electronico" disabled required/>
              </FloatingLabel>
              <FloatingLabel controlId="floatingNombre" label="Nombre(s)" className="mb-3">
                <Form.Control value={values.nombre} onChange={handleChange} name="nombre" placeholder="Escribe tu(s) nombre(s)" required />
              </FloatingLabel>
              <FloatingLabel controlId="floatingApellidos" label="Apellidos" className="mb-3">
                <Form.Control value={values.apellidos} onChange={handleChange} name="apellidos" placeholder="Escribe tus apellidos" required />
              </FloatingLabel>
              <FloatingLabel controlId="floatingTelefono" label="Telefono" className="mb-3">
                <Form.Control value={values.telefono} onChange={handleChange} name="telefono" placeholder="Escribe tu telefono" required />
              </FloatingLabel>
              <FloatingLabel controlId="floatingSector" label="Sector" className="mb-3">
                <Form.Control value={values.sector} onChange={handleChange} name="sector" placeholder="Escribe tu sector" required />
              </FloatingLabel>
              <FloatingLabel controlId="floatingCalle" label="Calle" className="mb-3">
                <Form.Control value={values.calle} onChange={handleChange} name="calle" placeholder="Escribe tu calle" required />
              </FloatingLabel>
              <FloatingLabel controlId="floatingNumero" label="Numero" className="mb-3">
                <Form.Control value={values.numero} onChange={handleChange} name="numero" placeholder="Escribe tu numero" required />
              </FloatingLabel>
              <Button disabled={isLoadingPost} type="submit" variant='success' className="text-center mx-auto mt-3 d-block">{isLoadingPost ? <Spinner animation="border" /> : "Actualizar Perfil"}</Button>
            </Form>
            )}
          </Formik>
          <Formik initialValues={{currentPassword: "", newPassword: "", newPassword2: ""}} onSubmit={(e) => handleSubmitClave(e)}>
            {({ handleSubmit, handleChange, values }) => (
            <Form id="formulario-pass" className='p-3 border-radius border mt-2' onSubmit={handleSubmit}>
              <h3 className="text-center">Actualiza tu contraseña</h3>
              <FloatingLabel controlId="floatingClave" label="Actual contraseña" className="mb-3">
                <Form.Control type='password' minlength="5" value={values.currentPassword} onChange={handleChange} name="currentPassword" placeholder="Escribe tu contraseña actual" required/>
              </FloatingLabel>
              <FloatingLabel controlId="floatingNewClave" label="Nueva contraseña" className="mb-3">
                <Form.Control type='password' minlength="5" value={values.newPassword} onChange={handleChange} name="newPassword" placeholder="Escribe tu nueva contraseña" required/>
              </FloatingLabel>
              <FloatingLabel controlId="floatingNewClave2" label="Repite tu nueva contraseña" className="mb-3">
                <Form.Control type='password' minlength="5" value={values.newPassword2} onChange={handleChange} name="newPassword2" placeholder="Escribe de nuevo tu nueva contraseña" required/>
              </FloatingLabel>
              <Button disabled={isLoadingPost} type="submit" variant='success' className="text-center mx-auto mt-3 d-block">{isLoadingPost ? <Spinner animation="border" /> : "Actualizar Contraseña"}</Button>
            </Form>
            )}
          </Formik>
        </Col>
      </Row>
      <Modal className="p-0" centered show={show} onHide={handleClose}>
        <Modal.Header className="border-0 pe-2 pt-2 pb-0" closeButton></Modal.Header>
        <Modal.Body className="border-0 py-0 d-flex flex-column">
          <h3 className='text-center'>Actualiza tu avatar</h3>
          <img src={`${URL_SERVER}filesUpload/images/avatars/${currentAvatar}`} className="bg-white rounded-circle p-2 img-fluid mx-auto border border-5  mt-2 mb-3" style={{height:"13rem", width:"13rem"}}/>
          <h4 className="text-center">Selecciona tu nuevo avatar</h4>
          <div className="d-flex flex-wrap justify-content-around border border-radius p-2">
            <Button className="p-0 rounded-circle m-1" onClick={() => handleNewAvatar(avatars[0].src)} variant='outline-success' style={{height:"6rem", width:"6rem"}}>
              <img  src={`${URL_SERVER}filesUpload/images/avatars/${avatars[0].src}`} className="bg-white rounded-circle p-2 img-fluid"/>
            </Button>
            <Button className="p-0 rounded-circle m-1" onClick={() => handleNewAvatar(avatars[1].src)} variant='outline-success' style={{height:"6rem", width:"6rem"}}>
              <img  src={`${URL_SERVER}filesUpload/images/avatars/${avatars[1].src}`} className="bg-white rounded-circle p-2 img-fluid"/>
            </Button>
            <Button className="p-0 rounded-circle m-1" onClick={() => handleNewAvatar(avatars[2].src)} variant='outline-success' style={{height:"6rem", width:"6rem"}}>
              <img  src={`${URL_SERVER}filesUpload/images/avatars/${avatars[2].src}`} className="bg-white rounded-circle p-2 img-fluid"/>
            </Button>
            <Button className="p-0 rounded-circle m-1" onClick={() => handleNewAvatar(avatars[3].src)} variant='outline-success' style={{height:"6rem", width:"6rem"}}>
              <img  src={`${URL_SERVER}filesUpload/images/avatars/${avatars[3].src}`} className="bg-white rounded-circle p-2 img-fluid"/>
            </Button>
            <Button className="p-0 rounded-circle m-1" onClick={() => handleNewAvatar(avatars[4].src)} variant='outline-success' style={{height:"6rem", width:"6rem"}}>
              <img  src={`${URL_SERVER}filesUpload/images/avatars/${avatars[4].src}`} className="bg-white rounded-circle p-2 img-fluid"/>
            </Button>
            <Button className="p-0 rounded-circle m-1" onClick={() => handleNewAvatar(avatars[5].src)} variant='outline-success' style={{height:"6rem", width:"6rem"}}>
              <img  src={`${URL_SERVER}filesUpload/images/avatars/${avatars[5].src}`} className="bg-white rounded-circle p-2 img-fluid"/>
            </Button>
            <Button className="p-0 rounded-circle m-1" onClick={() => handleNewAvatar(avatars[6].src)} variant='outline-success' style={{height:"6rem", width:"6rem"}}>
              <img  src={`${URL_SERVER}filesUpload/images/avatars/${avatars[6].src}`} className="bg-white rounded-circle p-2 img-fluid"/>
            </Button>
            <Button className="p-0 rounded-circle m-1" onClick={() => handleNewAvatar(avatars[7].src)} variant='outline-success' style={{height:"6rem", width:"6rem"}}>
              <img  src={`${URL_SERVER}filesUpload/images/avatars/${avatars[7].src}`} className="bg-white rounded-circle p-2 img-fluid"/>
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleUpdateAvatar}>
            Guardar cambios
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  )
}

export default Perfil