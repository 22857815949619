import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { Accordion, Button, Col, Container, Ratio, Row } from 'react-bootstrap';
import NavbarTop from '../componentes/include/Navbar';
import queryPost from '../Config/Consultas';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import { Link, useNavigate } from 'react-router-dom';
import api from '../Api.json';
import ReactPlayer from 'react-player'

const URL = `${api.url}${api.posts}`;
const URL_SERVER = api.url;

function Menu() {
  const navigate = useNavigate()
  const [posts, setPosts] = useState([])
  const [images, setImages] = useState([])
  const [verMasPost, setVerMasPost] = useState(6)

  const getPosts = async () => {
    const data = {
      accion: "getPosts"
    }
    const respuesta = await queryPost(URL, data);
    setPosts(respuesta)
  }

  const getImages = async () => {
    const data = {
      accion: "getImagesPosts"
    }
    const respuesta = await queryPost(URL, data);
    setImages(respuesta)
  }

  const galeria = idPost => {
    let arrayImagenes = []
    let index = null
    images.data.map((imagen, i) => {
      if(imagen.idPosts === idPost && imagen.type !== "pdf") {
        const img = {
          src: URL_SERVER + imagen.nombre
        }
        arrayImagenes.push(img)
        index = i
      }
    })
    if(index == 0)
      return (
        <Carousel 
        images={arrayImagenes} 
        className="w-100 bg-white" 
        canAutoPlay={false} 
        hasTransition={false} 
        hasThumbnails={false} 
        hasIndexBoard ={false} 
        hasLeftButton={false}
        hasRightButton={false}
        objectFit={"scale-down"}
        objectFitAtMax={"scale-down"}
        minIcon= {<i className='bx bx-exit-fullscreen text-white fs-1 m-2 textShadow'></i>}
        maxIcon= {<i className='bx bx-fullscreen text-white fs-1 m-2 textShadow' ></i>} />
      )
    else if(index >= 1)
      return (
        <div>
          <Carousel 
          images={arrayImagenes} 
          className="w-100  bg-white border" 
          canAutoPlay={false} 
          hasTransition={false} 
          hasThumbnails={false} 
          objectFit={"scale-down"}
          objectFitAtMax={"scale-down"}
          leftIcon={<i className='bx bx-chevron-left text-white fs-1 textShadow' ></i>}
          rightIcon={<i className='bx bx-chevron-right text-white fs-1 textShadow' ></i>}
          minIcon= {<i className='bx bx-exit-fullscreen text-white fs-1 m-2 textShadow'></i>}
          maxIcon= {<i className='bx bx-fullscreen text-white fs-1 m-2 textShadow' ></i>}
          style={{ height: 400}} />
        </div>
      )
  }

  useEffect(() => {
    if(localStorage.getItem('email') !== undefined && localStorage.getItem('email')){
      getPosts()
      getImages()
      moment.locale('es');
      const onScroll = function () {
       if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
         setVerMasPost(val => {return val + 5})
       }
      }
      window.addEventListener('scroll', onScroll)
      return () => window.removeEventListener('scroll', onScroll)
    }else{
      navigate(`/`);
    }
  }, [])
  
  
  const checkMont = (val1, val2) => {
    if(val2 !== undefined) {
        const date1 = moment(val1.createdAt).format('MMMM YYYY')
        const date2 = moment(val2.createdAt).format('MMMM YYYY')
        if(date1 === date2) return true
    }
    return false
  }

 
  return posts && (
    <Container className="p-0" style={{marginTop: 100}} >
      <NavbarTop className="m-0"/>
      <Row>
        <Col lg={8} >
          <Row >
            <Col xs={12} className="position-relative overflow-hidden  bg-opacity-75 px-5 py-3 border-radius d-flex align-items-center align-items-md-end shadow flex-column flex-md-row">
              <div className='w-100 h-100 position-absolute top-0 start-0 bg-blue'  style={{zIndex: -1}}>
                <img src='images/all/bg-menu.jpg' className='w-100 h-100 objectFitCover opacity-25'/>
              </div>
              <div className='rounded-circle overflow-hidden mt-4 mt-md-0' style={{height:"9rem", width:"9rem"}}>
                <img alt='avatar' src={`${URL_SERVER}filesUpload/images/avatars/${localStorage.getItem("avatar")}`} className="bg-white img-fluid p-2 rounded-circle" style={{objectFit: "scale-down"}}/>
              </div>
              <div className='px-4'>
                  <h1 className='text-center text-md-start text-white fw-bold text-capitalize'><i>{localStorage.getItem("nombre")}</i></h1>
                  <h5 className='text-center text-md-start text-white text-capitalize'><i>{localStorage.getItem("apellido")}</i></h5>
                  <p className='text-center text-md-start text-light mb-0'><i>Email: {localStorage.getItem("email")}</i></p>
                  <p className='text-center text-md-start text-light mb-0'><i>Telefono: {localStorage.getItem("telefono")}</i></p>
              </div>
              <div className='position-absolute end-0 me-md-3 me-4 d flex-'>
                  <a href="https://trello.com/b/GTg237qo/untitled-board" target="_blank" className='btn-light bg-light bg-opacity-75 text-primary rounded-pill py-1 px-2 me-1 fs-6 text-decoration-none'>REPORTES</a>
                  <Link to={`/Perfil/${localStorage.getItem("token")}`} className='btn-light bg-light bg-opacity-75 btn text-primary rounded-pill p-0 border-0'>
                    <i className='bx bx-edit fs-5 align-middle m-1'></i>
                  </Link>
              </div>
            </Col>
            <Col xs={12} className="d-flex flex-column align-items-center pb-5">
                { 
                !posts.empty &&
                <Col xs={11} md={12} lg={12} className="p-0 mt-4 bg-white shadow border-radius p-3">
                    <h1 className='text-center text-muted'>{posts.data}</h1>
                </Col> 
                }{
                posts.empty &&
                posts.data.slice(0,verMasPost).map((post,i) => {
                  return(
                    <Col key={i} xs={11} md={12} lg={12} className="p-0 mt-4">
                      <div className='shadow bg-white border border-radius overflow-hidden'>
                          <div className=" d-flex p-2 text-light bg-blue">
                              <div className='avatar overflow-hidden'>
                                <img alt='avatar' src={`${URL_SERVER}filesUpload/images/avatars/${post.avatar}`} className="bg-white img-fluid rounded-circle p-1" style={{objectFit: "scale-down"}}/>
                              </div>
                              <div className='d-flex flex-column ms-3'>
                                  <div className="fw-bold text-start fs-6">{post.email}</div>
                                  <div className=" badge text-start p-0 fw-light text-light">{moment(post.createdAt).fromNow()}</div>
                              </div>
                          </div>
                          <div className="pt-2 px-3 text-start fs-4 fw-bold border-1"> {post.titulo} </div>
                          <div className="px-3 text-start">{post.descripcion}</div>
                          <div className='mt-4'>
                              {post.video.trim() !== "" &&
                                <Ratio aspectRatio="16x9">
                                  <ReactPlayer className="w-100 h-100" controls={true} url={post.video} />
                                </Ratio>
                              }
                              <div className='overflow-hidden h-100 text-center'>
                                {images.data && galeria(post.id)}
                                {images.data && images.data.map((imagen, index) => {
                                    if(post.id === imagen.idPosts && imagen.type === "pdf") 
                                        return (
                                            <a key={index} href={URL_SERVER + imagen.nombre} target="_blank" className='float-start mb-2 p-2'>
                                              <img alt='avatar' src="images/icons/pdf.png" className="bg-white pdfMenu" style={{height: 120}}/>
                                            </a>
                                        ) 
                                  })
                                }
                              </div>
                          </div>
                      </div> 
                  </Col>
                  )
                })
                }
            </Col>
          </Row>
        </Col>
        {}
        <Col xs={4} className=' fixed-top ms-auto d-none d-lg-block ' style={{zIndex: 10}}>
          <div className='shadow border-radius bg-white pb-3 mx-auto div-meses  position-relative overflow-hidden'>
            <h5 className='bg-blue text-light text-start px-4 py-3 position-absolute top-0 start-0 w-100'><i>Publicaciones</i></h5>
            <Accordion defaultActiveKey="0" className='overflow-auto h-100 '>
            {posts.empty && posts.data && posts.data.map((value, i)=>{
                const meses = moment(value.createdAt).format('MMMM YYYY')
                return (<div key={i}>{
                  checkMont(posts.data[i], posts.data[i-1]) ?  null : 
                    <Accordion.Item eventKey={i} className='p-0 rounded-0 border-bottom border-0 font-montserrat'>
                      <Accordion.Header className="p-0"><p className='fw-bold d-inline py-3 text-capitalize fs-6 mb-0 text-secondary'>{meses}</p></Accordion.Header>
                      <Accordion.Body>
                      {
                        posts.data.map((titulo, index) => {
                          if(meses == moment(titulo.createdAt).format('MMMM YYYY')) return( 
                              
                          <Link to={`/Post/${titulo.id}`} key={index} className='btn btn-outline-primary border-0 d-flex align-items-start justify-content-start fs-6 p-1 ms-3'>
                            <i className='bx bxs-circle me-2 mt-1 d-inline'></i>
                            <p className='mb-0 d-inline text-start'>{titulo.titulo}</p>
                          </Link>
                          )
                        })
                      }
                      </Accordion.Body>
                    </Accordion.Item>
                }</div>)
            })
            }
            </Accordion>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Menu