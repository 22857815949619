import { Spinner } from 'react-bootstrap'
import Swal from 'sweetalert2'

export function alertaSuccess(text) {
    Swal.fire({
        title: 'Bien!',
        text: text,
        icon: 'success',
        confirmButtonText: 'OK'
      })
}
export function alertaError() {
    Swal.fire({
        title: 'Ocurrio un error!',
        text: 'Comunicate con el administrador',
        icon: 'error',
        confirmButtonText: 'OK'
      })
}

export function alertaLoading() {
    Swal.fire({
        imageUrl: 'images/icons/loading.gif',
        showConfirmButton: false,
        allowOutsideClick: false
    })
}

export function customAlert(title, text, icon) {
    Swal.fire({
        title: title,
        text: text,
        icon: icon,
        confirmButtonText: 'OK'
      })
}

export function alertaQuestion(text, textCancelarBtn, req) {
    Swal.fire({
        title: '¿Desas continuar?',
        text: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: textCancelarBtn,
        cancelButtonText: 'Cancelar'
    }).then((result) => {
        if (result.isConfirmed) {
            req()
        }
    })
}


