import React, { useEffect, useState } from 'react'
import { slide as Menu } from 'react-burger-menu'
import { Link } from 'react-router-dom'
import burger from '../../imagesGlobal/burger.png'
import logo from '../../imagesGlobal/logoColonos.png'
import { useRef } from 'react'
import moment from 'moment'

function NavbarTop(prop) {
  const [userIcon, setUserIcon ] = useState(<img src={burger}/>)
  const timeRef = useRef();
  const date = () => {
    return moment().format('DD/MM/YYYY, h:mm:ss A');
  };
  const logout = () => {
    localStorage.clear();
  }
  useEffect(() => {
    const aux = setInterval(() => {
      timeRef.current.innerHTML = `${date()}`;
    }, 1000);
    return () => clearInterval(aux);
  }, []);
  
  return (
    <div className="position-fixed top-0 start-0 bg-light shadow w-100 rounded-pill rounded-top pt-3" style={{height: 90, zIndex: 11}}>
        <div className='ps-3 pe-2 d-none d-md-flex justify-content-between align-items-center' style={{marginLeft: 160, marginRight: 110}}>
          <i className='text-start fs-5'>Asociación De Vecinos Fraccionamiento<br></br>Paisajes Del Tesoro</i>
          <div className='d-d-block mt-1'>
            <p ref={timeRef} className="text-end fw-light mb-0">{date()}</p>
            <p className='text-end fw-bold mb-0 text-capitalize'>{`${localStorage.getItem("tipoUsuario")} ${localStorage.getItem("nombre")}`}</p>
          </div>
        </div>
        <Link to={`/Home`} className="logo-button d-flex align-items-center justify-content-center">
          <img src={logo} alt="Colonos" className="img-fluid" />
        </Link>
        <Menu customBurgerIcon={userIcon} >
            <Link to={`/Home`} className='text-center rounded-pill text-decoration-none text-white py-2 fs-6 border-0 bg-blue-2'>Inicio</Link>
            <Link to={`/Perfil/${localStorage.getItem("token")}`}className='text-center rounded-pill text-decoration-none text-white py-2 fs-6 border-0 bg-blue-2 mt-2'>Perfil</Link>
            {localStorage.getItem("idUsuario") == 1 && <Link to={`/Posts`}className='text-center rounded-pill text-decoration-none text-white py-2 fs-6 border-0 bg-blue-2 mt-2'>Posts</Link>}
            {localStorage.getItem("idUsuario") == 1 && <Link to={`/Usuarios`} className='text-center rounded-pill text-decoration-none text-white py-2 fs-6 border-0 bg-blue-2 mt-2'>Usuarios</Link>}
            <Link onClick={logout} to={`/`} className='text-center rounded-pill text-decoration-none text-white py-2 fs-6 bg-red border-0  mt-4'>Cerrar sesion</Link>
        </Menu>
    </div>
  )
}

export default NavbarTop