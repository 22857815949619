import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row, Table } from 'react-bootstrap';
import NavbarTop from '../componentes/include/Navbar';
import queryPost from '../Config/Consultas';
import { alertaSuccess,alertaQuestion,alertaError, alertaLoading } from '../helpers/sweatalert2';
import api from '../Api.json';

const URL = `${api.url}${api.usuarios}`;
const URL_EMAIL = `${api.url}${api.sendEmail}`;

function Usuarios() {
  const [usuarios, setUsuarios] = useState([])
  
  const getUsers = async () => {
    const data = {
      accion: "getUsers"
    }
    const respuesta = await queryPost(URL, data);
    setUsuarios(respuesta)
    // console.log("respuesta desde el evento", res)
  }

  useEffect(() => {
    if(localStorage.getItem('email') === undefined || !localStorage.getItem('email')){
      localStorage.clear();
    }else{
      getUsers()
    }
  }, [])

  const handleDeleteUser =  (id, index) => {
    alertaQuestion(
      "Se eliminara el usuario permanentemente",
      "Eliminar",
      async () => {
        const dataDelete = {
          accion: "deleteUser",
          id: id
        }
        const respuesta = await queryPost(URL, dataDelete);
        if(!respuesta.error) {
          alertaSuccess(respuesta.message)
          const userUpdate = usuarios
          userUpdate.data.splice(index, 1);
          setUsuarios({...usuarios, userUpdate})
          // console.log(usuarios.data)
        }
        else alertaError()
      }
      )
  }

  const handleEstadoUser = async (id, estado, estadoText, index) => {
    alertaLoading()
    const dataEstado = {
      accion: "estadoUser",
      id: id,
      estado: estado
    }

    const respuesta = await queryPost(URL, dataEstado);

    if(!respuesta.error){
      const dataEmail = {
        accion: "estadoUser",
        id: id,
        estado: estado
      }
      const notificacion = await queryPost(URL_EMAIL, dataEmail)

      if(notificacion.error){
        alertaError()
      }else{
        alertaSuccess(respuesta.message)
      }
      const userUpdate = usuarios
      userUpdate.data[index].idTipoUsuario = estado
      userUpdate.data[index].etiquetaTipoUsuario = estadoText
      setUsuarios({...usuarios, userUpdate})
      // console.log(usuarios.data)
    }
    else alertaError()

  }

  return usuarios && (
    <Container className="p-0" style={{marginTop:110}}>
      <NavbarTop className="m-0"/>
      <h1 className="text-center fw-bold mb-3"><i>Usuarios</i></h1>
      <Row className="overflow-auto">
        <Col>
        <Table striped bordered hover size="sm" className="bg-white p-2 shadow text-center">
          <thead>
            <tr>
              <th>Correo electronico</th>
              <th>Nombre</th>
              <th>Apellidos</th>
              <th>Telefono</th>
              <th>Calle</th>
              <th>Numero #</th>
              <th>Sector</th>
              <th>Estado</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            { usuarios.data &&
              usuarios.data.map((val,i) => {
                return(
                  <tr key={i}>
                    <td className="align-middle">{val.email}</td>
                    <td className="align-middle">{val.nombre}</td>
                    <td className="align-middle">{val.apellidos}</td>
                    <td className="align-middle">{val.telefono}</td>
                    <td className="align-middle">{val.calle}</td>
                    <td className="align-middle">{val.numero}</td>
                    <td className="align-middle">{val.sector}</td>
                    {val.idTipoUsuario == 2 && 
                      <td className="align-middle">
                        <div className="rounded-pill bg-success p-1 d-flex align-items-center justify-content-center text-white">
                          {val.etiquetaTipoUsuario}
                        </div>
                      </td>
                    }
                    {val.idTipoUsuario == 3 && 
                      <td className="align-middle">
                        <div className="rounded-pill bg-secondary p-1 d-flex align-items-center justify-content-center text-white">
                          {val.etiquetaTipoUsuario}
                        </div>
                      </td>
                    }
                    <td>
                      {val.idTipoUsuario == 2 && 
                        <Button variant="outline-secondary" className="p-1 rounded-pill border-0" onClick={() => handleEstadoUser(val.id, "3", "Inactivo", i)}><i className='bx bxs-user-minus p-0 align-middle fs-4'></i></Button>
                      }
                      {val.idTipoUsuario == 3 && 
                        <Button variant="outline-primary" className="p-1 rounded-pill border-0" onClick={() => handleEstadoUser(val.id, "2", "Activo", i)}><i className='bx bxs-user-plus p-0 align-middle fs-4'></i></Button>
                      }
                      <Button variant="outline-danger" className="p-1 rounded-pill border-0" onClick={() => handleDeleteUser(val.id, i)}><i className='bx bxs-user-x p-0 align-middle fs-4'></i></Button>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
        </Col>
      </Row>
    </Container>
  )
}

export default Usuarios