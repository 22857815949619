export default async function queryPost(url,data) {
    const res = await fetch(url, {
        credentials: "same-origin",
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json"
        },
    })
    // console.log(res)
    const json = await res.json()
    // console.log(json)
    return json
}