import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { Accordion, Button, Col, Container, Ratio, Row } from 'react-bootstrap';
import NavbarTop from '../componentes/include/Navbar';
import queryPost from '../Config/Consultas';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import { Link, useParams } from 'react-router-dom';
import user from '../imagesGlobal/user.png'
import api from '../Api.json';

const URI_POST = `${api.url}${api.posts}`;
const URL_SERVER = api.url;


function Post() {
  const {id} = useParams()
  const [posts, setPosts] = useState([])
  const [post, setPost] = useState([])
  const [images, setImages] = useState([])

  const getPost = async () => {
    const data = {
      accion: "getPost",
      id: id
    }
    const respuesta = await queryPost(URI_POST, data);
    setPost(respuesta)
  }
  const getPosts = async () => {
    const data = {
      accion: "getPosts"
    }
    const respuesta = await queryPost(URI_POST, data);
    setPosts(respuesta)
    // console.log("respuesta desde el evento", respuesta)
  }
  const getImagesPost = async () => {
    const data = {
      accion: "getImagesPost",
      id: id
    }
    const respuesta = await queryPost(URI_POST, data);
    setImages(respuesta)
  }

  const galeria = idPost => {
    let arrayImagenes = []
    let index = null
    images.data.map((imagen, i) => {
      if(imagen.idPosts === idPost && imagen.type !== "pdf") {
        const img = {
          src: URL_SERVER + imagen.nombre
        }
        arrayImagenes.push(img)
        index = i
      }
    })
    if(index == 0)
      return (
        <Carousel 
        images={arrayImagenes} 
        className="w-100 bg-white" 
        canAutoPlay={false} 
        hasTransition={false} 
        hasThumbnails={false} 
        hasIndexBoard ={false} 
        hasLeftButton={false}
        hasRightButton={false}
        objectFit={"scale-down"}
        objectFitAtMax={"scale-down"}
        minIcon= {<i className='bx bx-exit-fullscreen text-white fs-1 m-2 textShadow'></i>}
        maxIcon= {<i className='bx bx-fullscreen text-white fs-1 m-2 textShadow' ></i>} />
      )
    else if(index >= 1)
      return (
        <div>
          <Carousel 
          images={arrayImagenes} 
          className="w-100  bg-white border" 
          canAutoPlay={false} 
          hasTransition={false} 
          hasThumbnails={false} 
          objectFit={"scale-down"}
          objectFitAtMax={"scale-down"}
          leftIcon={<i className='bx bx-chevron-left text-white fs-1 textShadow' ></i>}
          rightIcon={<i className='bx bx-chevron-right text-white fs-1 textShadow' ></i>}
          minIcon= {<i className='bx bx-exit-fullscreen text-white fs-1 m-2 textShadow'></i>}
          maxIcon= {<i className='bx bx-fullscreen text-white fs-1 m-2 textShadow' ></i>}
          style={{ height: 400}} />
        </div>
      )
  }

  useEffect(() => {
    if(localStorage.getItem('email') === undefined || !localStorage.getItem('email')){
      localStorage.clear();
    }else{
      getPosts()
      getPost()
      getImagesPost()
      moment.locale('es');
    }
  }, [id])

  const checkMont = (val1, val2) => {
    if(val2 !== undefined) {
        const date1 = moment(val1.createdAt).format('MMMM YYYY')
        const date2 = moment(val2.createdAt).format('MMMM YYYY')
        if(date1 === date2) return true
    }
    return false
  }

  
  return (
    <Container className="p-0" style={{marginTop: 100}}>
      <NavbarTop className="m-0"/>
      <Row>
        <Col lg={8} className="" >
          <Row>
            <Col xs={12} className="d-flex flex-column align-items-center pb-5">
                  {post.data &&
                      <Col xs={11} md={12} lg={12} className="p-0 mt-4">
                      <div className='shadow bg-white border border-radius overflow-hidden'>
                          <div className=" d-flex p-2 text-light bg-blue">
                              <img alt='avatar' src={user} className="avatar bg-white"/>
                              <div className='d-flex flex-column ms-3'>
                                  <div className="fw-bold text-start fs-6">{post.data.email}</div>
                                  <div className=" badge text-start p-0 fw-light text-light">{moment(post.data.createdAt).fromNow()}</div>
                              </div>
                          </div>
                          <div className="pt-2 px-3 text-start fs-4 fw-bold border-1"> {post.data.titulo} </div>
                          <div className="px-3 text-start">{post.data.descripcion}</div>
                          <div className='mt-4'>
                              {post.data.video.trim() !== "" &&
                                <Ratio aspectRatio="16x9">
                                  <iframe src={`https://www.youtube.com/embed/${post.data.video}?rel=0`} title="YouTube video"></iframe>
                                </Ratio>
                              }
                              <div className='overflow-hidden h-100 text-center'>
                                {images.data && galeria(post.data.id)}
                                {images.data && images.data.map((imagen, index) => {
                                    if(post.data.id === imagen.idPosts && imagen.type === "pdf") 
                                        return (
                                            <a key={index} href={URL_SERVER + imagen.nombre} target="_blank" className='float-start mb-2 p-2'>
                                              <img alt='avatar' src="images/icons/pdf.png" className="bg-white pdfMenu" style={{height: 120}}/>
                                            </a>
                                        ) 
                                  })
                                }
                                {/* <img alt='avatar' src="images/all/mapa.jpeg" className="img-fluid bg-white"/> */}
                              </div>
                          </div>
                      </div> 
                  </Col>
                }
            </Col>
          </Row>
        </Col>
        <Col xs={4} className=' fixed-top ms-auto d-none d-lg-block ' style={{zIndex: 10}}>
          <div className='shadow border-radius bg-white pb-3 mx-auto div-meses  position-relative overflow-hidden'>
            <h5 className='bg-blue text-light text-start px-4 py-3 position-absolute top-0 start-0 w-100'><i>Publicaciones</i></h5>
            <Accordion defaultActiveKey="0" className='overflow-auto h-100 '>
            {posts.data && posts.data.map((value, i)=>{
                const meses = moment(value.createdAt).format('MMMM YYYY')
                return (<div key={i}>{
                  checkMont(posts.data[i], posts.data[i-1]) ?  null : 
                    <Accordion.Item eventKey={i} className='p-0 rounded-0 border-bottom border-0 font-montserrat'>
                      <Accordion.Header className="p-0"><p className='fw-bold d-inline py-3 text-capitalize fs-6 mb-0 text-secondary'>{meses}</p></Accordion.Header>
                      <Accordion.Body>
                      {
                        posts.data.map((titulo, index) => {
                          if(meses == moment(titulo.createdAt).format('MMMM YYYY')) return( 
                              
                          <Link to={`/Post/${titulo.id}`} key={index} className='btn btn-outline-primary border-0 d-flex align-items-start justify-content-start fs-6 p-1 ms-3'>
                            <i className='bx bxs-circle me-2 mt-1 d-inline'></i>
                            <p className='mb-0 d-inline text-start'>{titulo.titulo}</p>
                          </Link>
                          )
                        })
                      }
                      </Accordion.Body>
                    </Accordion.Item>
                }</div>)
            })
            }
            </Accordion>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Post