import React, {useRef, useState} from 'react'
import { useEffect } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { auth } from '../auth/Auth';
import { useNavigate } from 'react-router-dom';
import queryPost from '../Config/Consultas';
import api from '../Api.json';

const URL = `${api.url}${api.login}`;

function Login(props) {

    const navigate = useNavigate()

    const [error, setError] = useState(null)
    const [espera, setEspera] = useState(false)

    const refUsuario = useRef(null);
    const refClave = useRef(null);

    const handleLogin = async (e) => {
        e.preventDefault()
        setEspera(true)
        const data = {
            "usuario" : refUsuario.current.value,
            "clave" : refClave.current.value
        }
        // console.log(data)
        const res = await queryPost(URL, data);
        // console.log("respuesta desde el evento", res)
        if(res.conectado){
            localStorage.setItem("email", res.usuario)
            localStorage.setItem("id", res.id)
            localStorage.setItem("nombre", res.nombre)
            localStorage.setItem("apellido", res.apellidos)
            localStorage.setItem("telefono", res.telefono)
            localStorage.setItem("avatar", res.avatar)
            localStorage.setItem("idUsuario", res.idTipoUsuario)
            localStorage.setItem("tipoUsuario", res.etiquetaTipoUsuario)
            localStorage.setItem("token", res.token)
            navigate(`/Home`)
        }
        props.acceder(res.conectado)
        setError(res.error)
        setEspera(false)
    }

    return (
    <Container fluid className='p-0 '>
        <Row className="login-bg min-100vh d-flex align-items-center justify-content-center">
            <Col lg={7} className="shadow">
                <Row>
                    <Col lg={6} className="p-0 d-none d-md-flex align-items-center justify-content-center bg-white">
                        <img src="images/all/logo.jpeg" className='img-fluid p-4' />  
                    </Col>
                    <Col lg={6} className="bg-white d-flex justify-content-center flex-column px-5 py-3 p-md-5">
                        <div className='fs-2 fw-bold text-center text-md-start text-dark mb-md-3'>Inicio de sesión</div>
                        <img src="images/all/login-icon.png" className="p-3 d-block mx-auto" height="120"/>
                        <Form onSubmit={handleLogin}>
                            <label className='fw-bold text-secondary'>Correo electronico: </label>
                            <div className="input-group mb-3 ">
                                <span className="input-group-text rounded-pill rounded-end" id="basic-addon1">@</span>
                                <input type="text" ref={refUsuario} className="form-control rounded-pill rounded-start" placeholder="Correo electronico" aria-label="Username" aria-describedby="basic-addon1"/>
                            </div>
                            <label className='fw-bold text-secondary'>Contraseña: </label>
                            <div className="input-group mb-3 ">
                                <span className="input-group-text rounded-pill rounded-end" id="basic-addon1"><i className='bx bxs-lock'></i></span>
                                <input ref={refClave} className="form-control rounded-pill rounded-start" placeholder="Contraseña" type="password" aria-label="password" aria-describedby="basic-addon1"/>
                            </div>
                            {
                                error && 
                                <div className="alert alert-danger">
                                    {error}
                                </div>
                            }
                            <button className="btn btn-outline-secondary mx-auto d-block mt-4 fw-bold mb-4" disabled={espera} type="submit">Ingresar <i className='bx bx-right-arrow-alt align-middle' ></i></button>
                        </Form>
                        <button className='btn btn-outline-secondary border-0 d-block mx-auto' onClick={() => navigate(`/RecuperarClave`)}>¿Olvidaste tu contraseña?</button>
                        <button className='btn btn-outline-secondary border-0 d-block mx-auto' onClick={() => navigate(`/Registro`)}>¡Registrate!</button>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>
  )
}

export default Login