import React, {useState} from 'react'
import {BrowserRouter, Routes, Route} from 'react-router-dom';  
import './App.css';
import { Container, Row, Col } from 'react-bootstrap';
import Login from './componentes/Login';
import Menu from './componentes/Menu';
import Registro from './componentes/Registro';
import Posts from './componentes/Posts';
import Perfil from './componentes/Perfil';
import Usuarios from './componentes/Usuarios';
import NuevoPost from './componentes/NuevoPost';
import EditarPost from './componentes/EditarPost';
import Post from './componentes/Post';
import RecuperarClave from './componentes/RecuperarClave';
import ConfimClave from './componentes/ConfimClave';

const PATH_BASENAME = ""

function App() {
  const [conectado, setConectado] = useState(false);

  const acceder = estado => {
    setConectado(estado)
  }

  return (
    <Container fluid className="p-0 overflow-hidden" >
      <BrowserRouter basename={PATH_BASENAME}>
        <Routes>
          <Route path="/" element={<Login acceder={acceder}/>}></Route>
          <Route path="/Home" element={<Menu/>}></Route>
          <Route path="/Registro" element={<Registro/>}></Route>
          <Route path="/Perfil/:id" element={<Perfil/>}></Route>
          <Route path="/Posts" element={<Posts/>}></Route>
          <Route path="/Post/:id" element={<Post/>}></Route>
          <Route path="/Usuarios" element={<Usuarios/>}></Route>
          <Route path="/NuevoPost" element={<NuevoPost/>}></Route>
          <Route path="/EditarPost/:id" element={<EditarPost/>}></Route>
          <Route path="/RecuperarClave" element={<RecuperarClave/>}></Route> 
          <Route path="/RecuperarClave/:token" element={<ConfimClave/>}></Route>
        </Routes>
      </BrowserRouter>
    </Container>
  );
}

export default App;
