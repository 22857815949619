import React, { useEffect, useState } from 'react'
import { Button, Col, Container, FloatingLabel, Form, Row, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import NavbarTop from '../componentes/include/Navbar';
import queryPost from '../Config/Consultas';
import { alertaSuccess,alertaError, alertaLoading } from '../helpers/sweatalert2';
import api from '../Api.json';

const URI = `${api.url}${api.posts}`;
const URL_EMAIL = `${api.url}${api.sendEmail}`;
const URL_SERVER = api.url;

function NuevoPost() {

    const navigate = useNavigate()
    const [imagenInput, setImagenInput] = useState([])
    const [isLoadingFile, setIsLoadingFile] = useState(false)
    const [isLoadingPost, setIsLoadingPost] = useState(false)
    const initialValues = {
        idAdmin: localStorage.getItem("id"),
        titulo: '',
        descripcion: '',
        video: '',
    }
    const [dataForm, setDataForm] = useState(initialValues)

    const handleFileChange = (e) => {
        setIsLoadingFile(true)
        let files = e.target.files;
        for(let i = 0; i < e.target.files.length; i++){
            setImagenInput([])

            const file = files[i]
            const name = file.name
            const type = name.split('.').pop()
            let preview = URL.createObjectURL(file)

            if(type === "pdf"){
                preview = "images/icons/pdf.png"
            }

            let fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = (event) => {
                const img = {
                    imgPreview: preview,
                    filePreview: URL.createObjectURL(file),
                    data: event.target.result,
                    type: type,
                    name: name
                }
                const newList = imagenInput
                newList.push(img)
                setImagenInput(newList)
            }
        }
        console.log(dataForm)
        setIsLoadingFile(false)
    }

    const handleInputChange = e => {
        setDataForm({
            ...dataForm, [e.target.name]: e.target.value
        })
    }

    const handleDeleteImg = index => {
        setImagenInput(files => files.filter((i, itemIndex) => index !== itemIndex))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        // alertaLoading()
        setIsLoadingPost(true)
        const data = {
            accion: "uploadPost",
            newFiles: imagenInput,
            dataForm: dataForm
        }
        const res = await queryPost(URI, data);
        if(!res.error){
            const dataEmail = {
                accion: "sendMail",
                titulo: res.titulo,
                idPost: res.idPost,
            }
            const resEmail = await queryPost(URL_EMAIL, dataEmail);
            if(resEmail.error){
                alertaError()
            }else{
                alertaSuccess(res.message)
            }
            navigate(`/Posts`)
        }else{
            alertaError()
        }
        setIsLoadingPost(false)
    }

    useEffect(() => {
        if(localStorage.getItem('email') === undefined || !localStorage.getItem('email')){
            localStorage.clear();
        }
    },[])

  return (
    <Container className='mb-4' style={{marginTop: 115}}>
        <NavbarTop className="m-0"/>
        <Row className="flex-column d-flex align-items-center p-0" >
            <Col xs={10} md={8} lg={5} className='border border-1 p-0 shadow border-radius bg-white'>
                {/* <h1 className="text-center mb-3 mt-3">Crear un nuevo Post</h1> */}
                <div className=" d-flex text-dark px-4 mt-4 align-items-center">
                    <img src={`${URL_SERVER}filesUpload/images/avatars/${localStorage.getItem("avatar")}`} className="avatar"/>
                    <div className="fw-bold text-start ms-3 fs-6">{localStorage.getItem("email")}</div>
                </div>
                <Form id="formulario-post" className='p-4' onSubmit={handleSubmit}>
                    <FloatingLabel controlId="floatingTitulo" label="Titulo" className="mb-3">
                        <Form.Control onChange={handleInputChange} required name="titulo" placeholder="Escribe un titulo para el post" />
                    </FloatingLabel>
                    <FloatingLabel controlId="floatingPost" label="Post" className="mb-3">
                        <Form.Control onChange={handleInputChange} required as="textarea" name="descripcion" placeholder="Escribe aqui tu post" style={{height: 140}} />
                    </FloatingLabel>
                    <FloatingLabel controlId="floatingVideo" label="Link del video" className="mb-3">
                        <Form.Control onChange={handleInputChange} name="video" placeholder="Link del video"/>
                    </FloatingLabel>
                    
                    <div className="d-flex align-items-center justify-content-end">
                        <Form.Label htmlFor="inputFileImage" className="btn btn-outline-secondary border-0 p-0"><i className='bx bxs-file-pdf fs-2 p-1'></i></Form.Label>
                        <Form.Label htmlFor="inputFilePdf" className="btn btn-outline-secondary border-0 p-0"><i className='bx bxs-image-add fs-2 p-1'></i></Form.Label>
                    </div>
                    
                    <Form.Control id="inputFileImage" className="d-none" type="file" name="pdf" accept=".pdf" onChange={handleFileChange} />
                    <Form.Control id="inputFilePdf" className="d-none" type="file" name="image" accept=".jpg, .jpeg, .png, .gif" multiple onChange={handleFileChange} />

                    <Row className="border-secondary p-2 rounded" style={{borderStyle: "dashed"}}>
                        {isLoadingFile ?
                            <Spinner animation="border" />
                        :
                        imagenInput !== null && 
                            imagenInput.map((val,i) => {
                                return (
                                    <Col key={i} xs={12} md={4} className='p-1 position-relative bg-secondary bg-opacity-10 rounded'>
                                        <div style={{height: 120}}>
                                            <img src={val.imgPreview} className="img-fluid h-100 mx-auto d-block" style={{objectFit: "cover"}} />
                                        </div>
                                        <p className="mb-0 p-1 text-truncate">{val.name}</p>
                                        <div className="position-absolute end-0 top-0 mt-1 me-2">
                                            <a target="_blank" href={val.filePreview} className='btn btn-primary p-0 mt-1 me-1'><i className='bx bx-show fs-6 p-1'></i></a>
                                            <Button variant='danger' onClick={() => handleDeleteImg(i)} className='p-0 mt-1'><i className='bx bx-trash fs-6 p-1'></i></Button>
                                        </div>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                    <Button disabled={isLoadingPost} type="submit" className="text-center mx-auto mt-3 d-block">{isLoadingPost ? <Spinner animation="border" /> : "Subir Post"}</Button>
                </Form>
            </Col>
        </Row>
    </Container>
  )
}

export default NuevoPost