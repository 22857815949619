import React from 'react'
import { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import queryPost from '../Config/Consultas';
import api from '../Api.json';
import { alertaSuccess } from '../helpers/sweatalert2';
import { useNavigate } from 'react-router-dom';

const URL = `${api.url}${api.registrar}`;

function Registro() {
    const navigate = useNavigate()
    const [espera, setEspera] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(null)
    const [dataForm, setDataForm] = useState({
        accion: 'registrar',
        nombre: '',
        apellido: '',
        correo: '',
        telefono: '',
        calle: '',
        numero: '',
        sector: '',
        clave: '',
    })
    
    const handleInputChange = e => {
        setDataForm({
            ...dataForm, [e.target.name]: e.target.value
        })
        // console.log(dataForm)
    }

    const handleSubmit = async e => {
        e.preventDefault()
        setEspera(true)

        const res = await queryPost(URL, dataForm);
        console.log("respuesta del registro", res)
        if(res.error){
            setError(res.message)
            setSuccess(null)
        }else{
            alertaSuccess(res.message)
            setSuccess(res.message)
            setError(null)
            document.getElementById("formulario-registro").reset();
            navigate("/")
        }
        setEspera(false)
    }

  return (
    <Container fluid className='p-0 '>
        <Row className="login-bg min-100vh d-flex align-items-center justify-content-center">
            <Col lg={8} className="shadow bg-white my-3">
                <Row>
                    <Col lg={7} className="p-2 d-flex align-items-center justify-content-center flex-column d-none d-md-block">
                        <img src="images/all/logo.jpeg" className='img-fluid' />  
                        <img src="images/all/mapa.jpeg" className='img-fluid' />  
                    </Col>
                    <Col lg={5} className="px-5 py-3 py-md-4 px-md-3">
                        <Form id="formulario-registro" onSubmit={handleSubmit} className="d-flex align-items-start justify-content-center flex-column h-100">
                            <h1 className="text-center fw-bold mx-auto">REGISTRO</h1>
                            <Form.Label className="fw-bold mb-0 mt-1">Nombre(s)</Form.Label>
                            <Form.Control onChange={handleInputChange} required name="nombre" placeholder='Escribe tu nombre'></Form.Control>
                            <Form.Label className="fw-bold mb-0 mt-1">Apellidos</Form.Label>
                            <Form.Control onChange={handleInputChange} required name="apellidos" placeholder='Escribe tus apellidos'></Form.Control>
                            <Form.Label className="fw-bold mb-0 mt-1">Correo electronico</Form.Label>
                            <Form.Control onChange={handleInputChange} required name="correo" type="email" placeholder='Escribe tu correo electronico'></Form.Control>
                            <Form.Label className="fw-bold mb-0 mt-1">Telefono</Form.Label>
                            <Form.Control onChange={handleInputChange} required name="telefono" type="tel" placeholder='Escribe tu telefono'></Form.Control>
                            <Form.Label className="fw-bold mb-0 mt-1">Calle</Form.Label>
                            <Form.Control onChange={handleInputChange} required name="calle" placeholder='Escribe tu calle'></Form.Control>
                            <Form.Label className="fw-bold mb-0 mt-1">Numero #</Form.Label>
                            <Form.Control onChange={handleInputChange} required name="numero" type="tel" placeholder='Escribe tu numero'></Form.Control>
                            <Form.Label className="fw-bold mb-0 mt-1">Sector</Form.Label>
                            <Form.Control onChange={handleInputChange} required name="sector" placeholder='Escribe tu sector'></Form.Control>
                            <Form.Label className="fw-bold mb-0 mt-1">Contraseña</Form.Label>
                            <Form.Control onChange={handleInputChange} required name="clave" minlength="5" type="password" placeholder='Escribe tu constraseña'></Form.Control>
                            <Button disabled={espera} type="submit" variant="outline-success" className="text-center mx-auto mt-4">REGISTRAR</Button>
                            {
                            error && 
                            <div className="alert alert-danger mx-auto mt-4 mb-0">
                                {error}
                            </div>
                            }
                            {
                            success && 
                            <div className="alert alert-success mx-auto mt-4 mb-0">
                                {success}
                            </div>
                            }
                        </Form>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>
  )
}

export default Registro