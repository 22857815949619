import React, { useState } from 'react'
import { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import queryPost from '../Config/Consultas'
import { alertaError, alertaLoading, alertaSuccess } from '../helpers/sweatalert2'
import api from '../Api.json';

const URL_USUARIOS = `${api.url}${api.usuarios}`;
const URL_EMAIL = `${api.url}${api.sendEmail}`;

function ConfimClave() {
    const [isRender, setIsRender] = useState(false)
    const navigate = useNavigate()
    const {token} = useParams()
    

    const recueprarClave = async () => {
        setIsRender(true)
        console.log("entroooooo")
        navigate("/")
        alertaLoading()
        const data = {
            accion: "generarClave",
            token: token
        }
        const query = await queryPost(URL_USUARIOS, data)
        if(query.error){
            alertaError()
        }else{
            const dataMail = {
                accion: "sendClave",
                clave: query.clave,
                email: query.email
            }
            const sendClave = await queryPost(URL_EMAIL, dataMail)
            if(sendClave.error){
                alertaError()
            }else{
                alertaSuccess(query.message)
            }
        }
    }

    useEffect(() => {
        if(!isRender){
            recueprarClave()
        }
    }, [token])

  return (
    <Container fluid>
        <Row className='login-bg min-100vh'>
            
        </Row>
    </Container>
  )
}

export default ConfimClave