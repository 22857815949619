import React, { useEffect, useState } from 'react'
import queryPost from '../Config/Consultas';
import api from '../Api.json';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useRef } from 'react';
import { alertaError, alertaLoading, alertaSuccess } from '../helpers/sweatalert2';

const URL_EMAIL = `${api.url}${api.sendEmail}`;

function RecuperarClave() {
    const [isDisabled, setIsDisabled] = useState(false)

    const refEmail = useRef(null)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsDisabled(true)
        alertaLoading()
        const data = {
            accion: "recuperarClave",
            email: refEmail.current.value
        }

        const query = await queryPost(URL_EMAIL, data)

        if(query.error){
            alertaError()
        }else{
            alertaSuccess("Se a enviado un enlace a tu correo electronico")
        }
        setIsDisabled(false)
    }

    return (
        <Container fluid>
            <Row className='login-bg min-100vh d-flex align-items-center justify-content-center'>
                <Col lg={7} className="bg-white shadow">
                    <Row>
                        <Col lg={6} className="p-0 d-none d-md-flex align-items-center justify-content-center bg-white shadow">
                            <img src="images/all/logo.jpeg" className='img-fluid p-4' />  
                        </Col>
                        <Col lg={6} className="d-flex flex-column align-items-center justify-content-center">
                            <img src="images/all/mail.svg" className="p-3 d-block mx-auto" height="120"/>
                            <Form onSubmit={handleSubmit}>
                                <Form.Label>Correo electronico</Form.Label>
                                <Form.Control ref={refEmail} type="email" placeholder="e-mail" />
                                <Button disabled={isDisabled} type='submit' variant='outline-secondary' className="mt-2 mx-auto d-block">Enviar</Button>
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default RecuperarClave